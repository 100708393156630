/**
 * Created by Reda on 12.07.2024
 */

var StickersShopWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            title: "StickersShopWindow.Title",
            name: "StickersShopWindow",
            content: this.createContent(),
            noBackground: true,
            notCloseByTouchInShadow: true
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STICKERS_SHOP_OPEN);

        cleverapps.focusManager.showControlsWhileFocused(["MenuBarGoldItem"]);
    },

    createContent: function () {
        var productViews = StickersBook.PRODUCTS.map(function (item) {
            var product = Product.CreateById(item);
            var stickersShopItem = new StickersShopItemView(product, this);
            var availableCollections = cleverapps.stickersBook.getAvailableCollections();
            if (product.availableAfterCollection
                && availableCollections.length <= product.availableAfterCollection) {
                stickersShopItem.disable();
            }
            return stickersShopItem;
        }, this);
        return new cleverapps.Layout(productViews, cleverapps.styles.StickersShopWindow.layoutOptions[cleverapps.resolution.mode]);
    },

    listBundles: function () {
        return ["stickers_shop"];
    }
});

cleverapps.styles.StickersShopWindow = {
    layoutOptions: [
        {
            direction: cleverapps.UI.VERTICAL,
            margin: 50,
            padding: 30
        },
        {
            direction: cleverapps.UI.HORIZONTAL,
            margin: 70
        },
        {
            direction: cleverapps.UI.HORIZONTAL,
            margin: 70
        }
    ]
};
