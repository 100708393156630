/**
 * Created by mac on 12/08/22
 */

var Tile3 = function (level, options) {
    TileGame.call(this, level, options);

    this.updateClovers();

    cleverapps.Random.seed(Date.now());

    this.counter.registerStage(33, this.checkLose.bind(this));
};

var Game = Tile3;

Tile3.prototype = Object.create(TileGame.prototype);
Tile3.constructor = Tile3;

Tile3.prototype.getInfo = function () {
    var info = TileGame.prototype.getInfo.call(this);

    info.open = this.open.getInfo();
    info.page = this.pagination.getCurrent();

    return info;
};

Tile3.prototype.checkLose = function () {
    if (this.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }
    if (this.open.checkOverflow()) {
        this.trigger("outOfMoves");

        return;
    }
    if (!this.table.isEmpty() && !this.isMoveExist()) {
        this.counter.inc();

        this.showMessage("message.noMoves", function () {
            this.lose();
            this.counter.dec();
        }.bind(this));
    }
};

Tile3.prototype.showScreen = function (f, silent) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            var controls = ["opencards"];
            cleverapps.Boosters.listBoostersIds().forEach(function (id) {
                controls.push("hint_" + id);
            });

            cleverapps.focusManager.showControlsWhileFocused(controls, silent);

            this.table.updateSize();
            this.table.showCards(f, silent);
        }.bind(this),

        function (f) {
            this.flip(f, silent);
        }.bind(this),
        function (f) {
            if (ExtraSlot.isAvailable()) {
                this.open.addExtraSlot();
            }
            f();
        }.bind(this)
    ]);
};

Tile3.prototype.isMoveExist = function () {
    return this.table.cards.filter(function (card) {
        return card.canPlay();
    }).length;
};

Tile3.prototype.setMoves = function () {
    cleverapps.audio.playSound(bundles.game.urls.return_booster_effect);
    Game.currentGame.log.clear();

    new ActionPlayer([
        function (f) {
            Game.currentGame.counter.setTimeout(f, 600);
        },
        function (f) {
            Game.currentGame.counter.setTimeout(f, 400);
            var lastCards = this.open.shift3Cards();
            this.table.push3CardsToStack(lastCards);
            this.table.animateUpdateSize();
            lastCards.forEach(function (card, index) {
                this.table.returnCard(card, lastCards.length - index);
            }.bind(this));
        }.bind(this)
    ]).play();
};

Tile3.prototype.updateClovers = function () {
    this.clovers = 0;

    this.table.cards.forEach(function (card) {
        if (card.findComponent(PlateOnCard)) {
            this.clovers++;
        }
    }.bind(this));

    this.trigger("updateCloversTarget");
};

Tile3.prototype.onAcceptProlongationOffer = function (offer) {
    GameBase.prototype.onAcceptProlongationOffer.call(this, offer);
    if (offer.type === Prolongation.TYPES.BOMB && this.open.isFull()) {
        this.setMoves();
    }
};