/**
 * Created by Ivan on 19.05.2024
 */

var StickersCompletedWindow = CleverappsWindow.extend({
    ctor: function (index) {
        this.collectionIndex = index;

        this._super.apply(this, arguments);
    },

    onWindowLoaded: function () {
        var content = this.createContent();

        this._super({
            content: content,
            name: "StickersCompletedWindow",
            noBackground: true,
            title: "StickersCompletedWindow.Title",
            closeButtonDelay: 1
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.StickersCompletedWindow;

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize(styles.width, styles.height);

        this.stickers = [];

        this.createCollection();

        var rewardList = this.rewardList = new RewardsListComponent(StickersBook.getCollectionReward(this.collectionIndex), {
            font: cleverapps.styles.FONTS.WHITE_TEXT,
            margin: styles.rewardList.margin,
            event: cleverapps.EVENTS.EARN.OTHER
        });
        rewardList.setVisible(false);
        rewardList.setCascadeOpacityEnabledRecursively(true);
        rewardList.setOpacity(0);
        content.addChild(rewardList);
        rewardList.setPositionRound(styles.layout);

        return content;
    },

    createCollection: function () {
        var styles = cleverapps.styles.StickersCompletedWindow;

        this.stickers = cleverapps.stickersBook.collections[this.collectionIndex].stickers.map(function (sticker) {
            var ViewClass = sticker.getViewClass();
            var stickerView = new ViewClass(sticker);
            return stickerView;
        });

        for (var index = 0; index < this.stickers.length; index++) {
            this.content.addChild(this.stickers[index], this.stickers.length - index);
            this.stickers[index].setPositionRound(styles.stickers);
        }

        this.setupStickersPositions();
    },

    stickersCompleteAnimation: function (isReverse, animationDelay) {
        var styles = cleverapps.styles.StickersCompletedWindow;
        animationDelay = animationDelay || 0;

        for (var index = 1; index < this.stickers.length; index++) {
            var stickerView = this.stickers[index];
            var position, rotation, delay;
            var pairIndex = Math.floor((index + 1) / 2);

            if (isReverse) {
                position = stickerView.getPosition();
                rotation = stickerView.getRotation();
                delay = pairIndex * 0.08;
                stickerView.setPosition(stickerView.calculatePositionRound(styles.stickers));
                stickerView.setRotation(0);
            } else {
                position = stickerView.calculatePositionRound(styles.stickers);
                rotation = -stickerView.getRotation();
                delay = (Math.floor(this.stickers.length / 2) - pairIndex) * 0.08;
            }

            stickerView.runAction(new cc.Sequence(
                new cc.DelayTime(animationDelay + delay),
                new cc.Spawn(
                    new cc.MoveTo(0.35, position.x, position.y).easing(isReverse ? cc.easeBackOut() : cc.easeBackIn()),
                    new cc.RotateBy(0.35, rotation)
                )
            ));
        }
    },

    onShow: function () {
        this._super();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.stickers_completed_window.urls.stickers_open_effect);
            })
        ));

        this.stickersCompleteAnimation(true, 0.5);

        var rewardOffsetY = cleverapps.styles.StickersCompletedWindow.rewardOffsetY;
        this.rewardList.runAction(new cc.Sequence(
            new cc.DelayTime(1.25),
            new cc.MoveBy(0, 0, rewardOffsetY),
            new cc.Show(),
            new cc.Spawn(
                new cc.FadeIn(0.2),
                new cc.MoveBy(0.2, 0, -rewardOffsetY).easing(cc.easeBackOut())
            )
        ));
    },

    setupStickersPositions: function () {
        var styles = cleverapps.styles.StickersCompletedWindow;

        var offsetY = 0;
        for (var index = 0; index < this.stickers.length; index++) {
            var stickerView = this.stickers[index];
            stickerView.setPositionRound(styles.stickers);

            var isEvenIndex = index && index % 2 === 0;
            var pairIndex = Math.floor((index + 1) / 2);
            var offsetX = pairIndex * styles.offset.x * (isEvenIndex ? -1 : 1);
            var rotateAngle = pairIndex * 3 * (isEvenIndex ? -1 : 1);

            stickerView.setPositionRound(cc.p(stickerView.getPosition().x + offsetX, stickerView.getPosition().y + offsetY));
            stickerView.setRotation(rotateAngle);

            offsetY -= styles.offset.y * index * isEvenIndex;
        }
    },

    beforeCloseAnimation: function (callback) {
        this.rewardList.receiveRewards();
        this.rewardList.receiveRewardsAnimation({
            callback: callback
        });

        this.stickersCompleteAnimation();

        this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.stickers_completed_window.urls.stickers_close_effect);
            }),
            new cc.DelayTime(0.95),
            new cc.CallFunc(function () {
                this.windowTitle.hide();
            }.bind(this)),
            new cc.DelayTime(0.3),
            new cc.CallFunc(this.hideSelf.bind(this))
        ));
    },

    listBundles: function () {
        return ["stickers_completed_window"].concat(["collection_" + this.collectionIndex]);
    }
});

cleverapps.styles.StickersCompletedWindow = {
    width: 650,
    height: 900,

    layout: {
        x: { align: "center" },
        y: { align: "center", dy: -200 },
        margin: 50
    },

    rewardOffsetY: 100,

    rewardList: {
        margin: 30
    },

    stickers: {
        x: { align: "center" },
        y: { align: "center", dy: 170 }
    },

    offset: {
        x: 80,
        y: 4
    }
};
