/**
 * Created by olga on 13.11.2023
 */

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    Title: {
        fitTo: {
            width: 360,
            height: 70
        },
        dx: 10,
        dy: 324,
        delay: 0.5,
        duration: 0.675
    },

    discountLabel: {
        dx: 237,
        dy: 366,
        delay: 1,
        duration: 0.675,
        fitTo: {
            width: 100
        }
    },

    animation: {
        "default": {
            idleDelay: undefined,
            dx: 40,
            dy: 0
        },

        "starterPack0": {
            idleAnimation: "0_idle",
            introAnimation: "0_start"
        },

        "starterPack": {
            idleAnimation: "1_idle",
            introAnimation: "1_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.OpenCardsView, {
    bg: {
        y: { align: "bottom", dy: -22 }
    },
    fg: {
        y: { align: "bottom", dy: -22 }
    },
    slotSize: 120
});
