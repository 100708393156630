/**
 * Created by Ivan on 31.07.2024
 */

var StickersPackView = cc.Node.extend({
    ctor: function (packSpine) {
        this._super();

        var styles = cleverapps.styles.StickersPackView;

        this.setAnchorPoint(0.5, 0.5);
        this.setCascadeOpacityEnabledRecursively(true);
        this.setVisible(false);

        var cardsIcon;
        if (packSpine) {
            cardsIcon = new cleverapps.Spine(bundles.stickers_reward_window.jsons.sticker_pack_json);
            this.createPackAnimations();
        } else {
            cardsIcon = new cc.Sprite(bundles.stickers_reward_window.frames.sticker_pack_png);
            this.setOpacity(0);
            this.setScale(0.6);
        }

        cardsIcon.setPositionRound(styles.cards_icon);
        cardsIcon.setLocalZOrder(1000);
        this.cardsIcon = cardsIcon;
        this.addChild(cardsIcon);

        cleverapps.UI.wrap(this);
        cardsIcon.setPositionRound(this.width / 2, this.height / 2);
    },

    createPackAnimations: function () {
        var styles = cleverapps.styles.StickersPackView;

        var lightAnimation = this.lightAnimation = new cleverapps.Spine(bundles.stickers_reward_window.jsons.sticker_pack_light_json);
        lightAnimation.setPositionRound(styles.lightAnimation);
        this.addChild(lightAnimation, -1);

        var particlePathAnimation = this.particlePathAnimation = new cleverapps.Spine(bundles.stickers_reward_window.jsons.sticker_pack_particle_track_json);
        particlePathAnimation.setPositionRound(styles.lightAnimation);
        this.addChild(particlePathAnimation, 1);

        var particleAnimation = this.particleAnimation = new cleverapps.Spine(bundles.stickers_reward_window.jsons.sticker_pack_particle_json);
        particleAnimation.setPositionRound(styles.particle);
        this.addChild(particleAnimation);
    },

    createOpenAction: function () {
        return new cc.Spawn(
            new cc.CallFunc(function () {
                this.cardsIcon.setAnimation(0, "animation", false);
                this.lightAnimation.setAnimation(0, "open", false);
                this.particlePathAnimation.setAnimation(0, "animation", false);

                this.lightAnimation.runAction(new cc.Sequence(
                    new cc.ScaleTo(0.5, 1, 2),
                    new cc.ScaleTo(0.1, 1, 1)
                ));
            }.bind(this)),
            new cc.DelayTime(0.6)
        );
    },

    createShowAction: function () {
        var position = this.getPosition();
        this.setPosition(this.x, -this.y - this.height);

        return new cc.Sequence(
            new cc.Show(),
            new cc.CallFunc(function () {
                this.cardsIcon.setAnimation(0, "idle", true);
            }.bind(this)),
            new cc.MoveTo(0.5, position.x, position.y).easing(cc.easeBackOut()),
            new cc.CallFunc(function () {
                this.cardsIcon.setAnimationAndIdleAfter("open", "idle2");
                this.lightAnimation.setAnimation(0, "idle", true);
                this.particleAnimation.setAnimation(0, "animation", true);
                this.setPositionRound(position);
            }.bind(this))
        );
    },

    createHideAction: function () {
        return new cc.Sequence(
            new cc.MoveTo(0.5, this.x, this.y).easing(cc.easeBackIn()),
            new cc.Hide()
        );
    },

    showAnimation: function () {
        this.runAction(new cc.Sequence(
            this.createShowAction(),
            this.createOpenAction(),
            this.createHideAction()
        ));
    }
});

cleverapps.styles.StickersPackView = {
    lightAnimation: {
        x: { align: "center" },
        y: { align: "bottom", dy: 70 }
    },

    particle: {
        x: { align: "center" },
        y: { align: "bottom", dy: 120 }
    }
};