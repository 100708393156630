/**
 * Created by mac on 12/08/22
 */

var Tile3Scene = TileGameScene.extend({
    onSceneLoaded: function () {
        this._super();

        var openCardsView = this.openCardsControl = new OpenCardsView(this.game.open);
        this.addChild(openCardsView);

        var shuffleBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_TILE_SHUFFLE);
        if (shuffleBooster && shuffleBooster.isAvailable()) {
            var shuffleButton = new TileShuffleButtonBooster(shuffleBooster);
            this.addChild(shuffleButton);

            this.shuffleButton = new HidingNode(shuffleButton, cleverapps.UI.HORIZONTAL);
            cleverapps.focusManager.registerControl(shuffleButton.getControlId(), this.shuffleButton);
        }

        var undoBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_UNDO);
        if (undoBooster && undoBooster.isAvailable()) {
            var undoButton = new UndoButtonBooster(undoBooster);
            this.addChild(undoButton);

            this.undoButton = new HidingNode(undoButton, cleverapps.UI.HORIZONTAL);
            cleverapps.focusManager.registerControl(undoButton.getControlId(), this.undoButton);
        }

        var vacuumBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_VACUUM);
        if (vacuumBooster && vacuumBooster.isAvailable()) {
            var vacuumButton = new VacuumButtonBooster(vacuumBooster);
            this.addChild(vacuumButton);

            this.vacuumButton = new HidingNode(vacuumButton, cleverapps.UI.HORIZONTAL);
            cleverapps.focusManager.registerControl(vacuumButton.getControlId(), this.vacuumButton);
        }
    },

    outOfMoves: function () {
        cleverapps.focusManager.display({
            focus: "ProlongationWindow",
            actions: [
                function (f) {
                    cleverapps.focusManager.clearUpdateTimeout();
                    cleverapps.timeouts.setTimeout(f, OpenCards.OVERFLOW_ANIMATION_DURATION);
                },
                function (f) {
                    cleverapps.focusManager.updateControlsVisibility();
                    if (cleverapps.gameModes.skipProlongation) {
                        this.game.lose();
                        f();
                        return;
                    }

                    new ProlongationWindow(this.game.prolongation, {
                        onGiveUp: function () {
                            this.game.lose();
                        }.bind(this),
                        onSuccess: function () {
                            if (this.game.open.extraSlot && this.game.open.extraSlot.isOfferAvailable()) {
                                this.game.open.extraSlot.trigger("removeExtraSlotButton", this.game.open.extraSlot);
                            }
                        }.bind(this)
                    });

                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this)
            ]
        });
    }
});

GameScene = Tile3Scene;
