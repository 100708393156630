/**
 * Created by Ivan on 18.04.2024
 */

var StickersBookToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.STICKERS_BOOK);

    this.enable();

    cleverapps.stickersBook.onChangeAttentionListener = this.onChangeState.bind(this);
    this.onChangeState();
};

StickersBookToolbarItem.prototype = Object.create(ToolbarItem.prototype);
StickersBookToolbarItem.prototype.constructor = StickersBookToolbarItem;

StickersBookToolbarItem.prototype.onChangeState = function () {
    if (cleverapps.stickersBook.hasAttention()) {
        this.mark();
    } else {
        this.unmark();
    }
};

StickersBookToolbarItem.prototype.onClick = function () {
    cleverapps.focusManager.display({
        focus: "StickersBook",
        action: function (f) {
            cleverapps.focusManager.onceNoWindowsListener = f;
            new StickersBookWindow(cleverapps.stickersBook);
        }
    });
};

StickersBookToolbarItem.prototype.isVisible = function () {
    return cleverapps.stickersBook.isAvailable();
};
