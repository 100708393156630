/**
 * Created by Reda on 19.09.2024
 */

var StickersCollectComponent = cc.Node.extend({
    ctor: function (reward) {
        this._super();

        this.stickers = reward.stickers;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.resolution.getSceneSize());
        this.setPositionRound(cleverapps.styles.StickersCollectComponent);

        this.createStickersLayout();
        this.createStickersSource();
    },

    createStickersLayout: function () {
        var styles = cleverapps.styles.StickersCollectComponent;

        var stickersViews = this.stickersViews = this.stickers.map(function (sticker) {
            return new RewardStickerView(sticker, this);
        }.bind(this));

        var sceneSize = cleverapps.resolution.getSceneSize();
        var gridSize = cc.size(sceneSize.width * 0.5, sceneSize.height * 0.7);

        var stickersLayout = this.stickersLayout = new cleverapps.WrapGridLayout(stickersViews, {
            columns: this.calcColumnsCount(stickersViews.length),
            margin: styles.margin,
            fitToBox: gridSize,
            padding: styles.padding
        });
        this.addChild(stickersLayout);
        stickersLayout.setPositionRound(styles.content);
    },

    createStickersSource: function () {
        var stickersSource = this.stickersSource = new StickersPackView(bundles.stickers_reward_window.jsons.sticker_pack_json);
        this.addChild(stickersSource);
        stickersSource.setPositionRound(cleverapps.styles.StickersCollectComponent.stickersSource);
    },

    calcColumnsCount: function (stickersAmount) {
        if (stickersAmount <= 3) {
            return stickersAmount;
        }

        if (stickersAmount === 4) {
            return 2;
        }

        if (stickersAmount === 6) {
            return 3;
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            return 5;
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            return 4;
        }

        return 3;
    },

    setupChildren: function () {
        var scene = cleverapps.scenes.getRunningScene();
        var position = scene.convertToWorldSpace(cc.p(scene.width / 2, scene.height / 2));
        this.setPositionRound(this.parent.convertToNodeSpace(position));
    },

    getReceiveDuration: function () {
        return 0.7;
    },

    createReceiveAnimation: function () {
        var delays = cleverapps.Random.shuffle(this.stickersViews.map(function (sticker, index) {
            return index;
        }));

        return new cc.Spawn(
            new cc.CallFunc(function () {
                this.stickersViews.forEach(function (sticker, index) {
                    sticker.receiveStickerAnimation(delays[index] * 0.1, sticker);
                });
            }.bind(this)),
            new cc.DelayTime(this.stickersViews.length * 0.1 + this.getReceiveDuration())
        );
    },

    createCollectAnimation: function () {
        return new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(function () {
                this.stickersViews.forEach(function (stickerView) {
                    stickerView.collectStickerAnimation();
                });
            }.bind(this)),
            new cc.DelayTime(0.2),
            new cc.FadeOut(0.3)
        );
    },

    getCollectAnimation: function () {
        return new cc.Sequence(
            new cc.DelayTime(1),
            new cc.Spawn(
                new cc.CallFunc(function () {
                    this.stickersSource.showAnimation();
                }.bind(this)),
                new cc.DelayTime(1.6)
            ),
            this.createReceiveAnimation(),
            new cc.DelayTime(1.5),
            this.createCollectAnimation(),
            new cc.RemoveSelf()
        );
    }
});

cleverapps.styles.StickersCollectComponent = {
    x: { align: "center" },
    y: { align: "center" },

    size: [
        { width: 650, height: 900 },
        { width: 1700, height: 700 },
        { width: 1800, height: 900 }
    ],

    content: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -50 }
    },

    stickersSource: {
        x: { align: "center" },
        y: { align: "bottom", dy: -100 }
    },

    stickerStarPosOffsetY: 200,

    margin: {
        x: 150,
        y: 200
    },

    padding: {
        top: 20,
        bottom: 20
    }
};